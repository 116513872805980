// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.mobile-menu').toggleClass('is-active');
		$('html').toggleClass('locked');

	});
	$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
	});
	
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100
		});
	
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);
	
	

	
	
});
